<template>
    <div class="w-full">
        <v-card class="w-full mx-auto max-w-full sm:max-w-4xl xl:max-w-2xl overflow-hidden">
            <v-section-heading class="border-b-2 border-default">
                Tip Your Sherpa
            </v-section-heading>

            <v-card-content class="border-b-2 border-default">
                <template v-if="!isTransactionComplete">
                    <v-text>
                        You are about to tip your Sherpa for a job well done.
                        <br>
                        Please select a job and then complete the payment form below.
                    </v-text>
                </template>

                <div v-else class="text-center">
                    <v-text>
                        Thank you for tipping! Your Sherpa greatly appreciates it.
                    </v-text>
                </div>
            </v-card-content>

            <template v-if="isTransactionComplete">
                <v-card-content class="text-center">
                    <v-button href="/dashboard" color="primary">
                        Home
                    </v-button>
                </v-card-content>
            </template>

            <template v-if="!isTransactionComplete">
                <div class="w-full overflow-hidden overflow-x-scroll">
                    <v-table>
                        <v-table-body>
                            <template v-if="isInitializing">
                                <v-table-row>
                                    <v-table-data colspan="100%" align="center">
                                        <v-loader />
                                    </v-table-data>
                                </v-table-row>
                            </template>

                            <template v-else>
                                <template v-if="availableJobs && availableJobs.length">
                                    <template v-for="(job, jobIndex) in availableJobs">
                                        <v-table-row :key="'job_' + jobIndex">
                                            <v-table-data :full-width="false" class="hidden sm:block">
                                                <div class="w-7 h-7">
                                                    <a v-tooltip="{ content: findAvailableGame(job.game_id).name }" href="#">
                                                        <img :src="findAvailableGame(job.game_id).logo_dark_url" class="mx-auto w-7 h-7">
                                                    </a>
                                                </div>
                                            </v-table-data>

                                            <v-table-data :whitespace-no-wrap="false">
                                                <v-text class="font-medium">
                                                    {{ job.primary_sherpa ? job.primary_sherpa.name : "" }}
                                                </v-text>
                                                {{ job.product_name }}
                                            </v-table-data>

                                            <v-table-data :full-width="false" align="right">
                                                <v-link :to="{ path: '#' }" class="hidden sm:block">
                                                    Job #{{ job.order_line_id }}
                                                </v-link>

                                                <span class="hidden sm:block">
                                                    {{ job.order_line_status_name }}
                                                </span>
                                            </v-table-data>

                                            <v-table-data :full-width="false">
                                                <input v-model="selectedJobId" :value="job.order_line_id" type="radio" class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-200" :disabled="isLoading">
                                            </v-table-data>
                                        </v-table-row>
                                    </template>
                                </template>

                                <template v-else>
                                    <v-table-row-no-results />
                                </template>
                            </template>
                        </v-table-body>
                    </v-table>
                </div>
            </template>

            <v-form v-if="!isTransactionComplete" id="payment-form" class="" @submit.prevent="stripePay">
                <div v-show="selectedJob && selectedJob.order_line_id" class="px-6 py-6 border-t-2 border-default">
                    <div id="error-message">
                        <!-- Display error message to your customers here -->
                    </div>

                    <div id="payment-element">
                        <!-- Elements will create form elements here -->
                    </div>

                    <v-form-group class="grid grid-cols-1 sm:grid-cols-7 gap-4 mt-6">
                        <v-form-group class="col-span-5">
                            &nbsp;
                        </v-form-group>

                        <v-form-group class="col-span-2">
                            <v-form-label>Amount</v-form-label>
                            <v-form-input v-model="transaction.amount" type="amount" name="amount" min="1" :required="true" :disabled="isLoading" />
                        </v-form-group>
                    </v-form-group>
                </div>

                <v-form-group class="border-t-2 border-default">
                    <v-card-content>
                        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4 ">
                            <v-form-group class="flex items-center sm:items-center justify-center">
                                <!-- <v-form-label>&nbsp;</v-form-label> -->
                                <v-link href="/dashboard" :disabled="isLoading">
                                    Cancel
                                </v-link>
                            </v-form-group>

                            <v-form-group class="col-span-2">
                                &nbsp;
                            </v-form-group>

                            <v-form-group class="flex items-start sm:items-end">
                                <!-- <v-form-label>&nbsp;</v-form-label> -->
                                <v-button color="primary" class="w-full px-0" type="submit" :disabled="isLoading || !selectedJobId || transaction.amount < 0.5">
                                    Send Tip
                                </v-button>
                            </v-form-group>
                        </div>
                    </v-card-content>
                </v-form-group>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

export default {
    data() {
        return {
            /**
             * The request client used to make requests to the API.
             */
            requestClient: axios.create({
                baseURL: process.env.VUE_APP_API_URL,
                timeout: 10000,
                headers: {
                    Authorization: `Bearer ${this.$route.params.authToken}`,
                },
            }),

            /**
             * The available games
             */
            availableGames: [],

            /**
             * The available jobs to select from.
             */
            availableJobs: [],

            /**
             * The selected job ID.
             */
            selectedJobId: "",

            /**
             * @todo Write a description.
             */
            transaction: {
                amount: 10,
                paymentIntentId: "",
                clientSecret: "",
                elements: {},
                stripe: {},
                customer: null,
            },

            isTransactionComplete: this.$route.query.is_complete || false,
        };
    },
    computed: {
        /**
         * The selected job inferred from the available jobs and the selected job id.
         */
        selectedJob() {
            if (!this.availableJobs) {
                return null;
            }

            return this.availableJobs.find((job) => job.order_line_id === this.selectedJobId);
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        /**
         * Initialize the component.
         */
        initialize() {
            this.requestClient.get("/api/me")
                .then((response) => {
                    const { user_id: userId } = response.data;
                    this.transaction.customer = response.data;
                    return this.requestClient.get(`/api/users/${userId}/jobs`);
                })
                .then((response) => {
                    this.availableJobs = response.data.data.filter((job) => ((job.primary_sherpa && job.primary_sherpa.name) && job.game_id));
                    return this.requestClient.get("/api/games");
                })
                .then((response) => {
                    this.availableGames = response.data.data;
                })
                .then(async () => {
                    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);

                    if (!this.isTransactionComplete) {
                        this.stripeCreate();
                    }
                })
                .catch((error) => {
                    console.error("error", error);
                })
                .finally(() => {
                    this.toggleInitialize();
                });
        },

        toggleIsTransactionComplete() {
            this.isTransactionComplete = !this.isTransactionComplete;
        },
        stripeCreate() {
            this.toggleLoading();
            this.requestClient.post("/api/stripe/payment-intents/create", 1)
                .then((response) => {
                    const {
                        client_secret: clientSecret,
                        id: paymentIntentId,
                        amount,
                    } = response.data;

                    this.transaction.paymentIntentId = paymentIntentId;
                    this.transaction.amount = amount / 100;

                    this.elements = this.stripe.elements({
                        clientSecret,
                        appearance: {
                            theme: "night",
                            variables: {
                                colorText: "#D6D3D1",
                            },
                        },
                    });

                    (this.elements.create("payment")).mount("#payment-element");
                })
                .catch((error) => {
                    console.error("error", error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        stripePay() {
            this.toggleLoading();

            this.requestClient.post("/api/stripe/payment-intents/update", {
                payment_intent_id: this.transaction.paymentIntentId,
                amount: this.transaction.amount,
            })
                .then(async (response) => {
                    const { error } = await this.stripe.confirmPayment({
                        elements: this.elements,
                        confirmParams: {
                            receipt_email: this.transaction.customer.email ?? "",
                            return_url: `${process.env.VUE_APP_API_URL}/api/conversation-commands/1/callback?order_line_id=${this.selectedJobId}&paymentIntentId=${response.data.id}&authToken=${this.$route.params.authToken}`,
                        },
                    });

                    if (error) {
                        // This point will only be reached if there is an immediate error when
                        // confirming the payment. Show error to your customer (for example, payment
                        // details incomplete)
                        this.$eventBus.$emit("error", error);
                    } else {
                        // Your customer will be redirected to your `return_url`. For some payment
                        // methods like iDEAL, your customer will be redirected to an intermediate
                        // site first to authorize the payment, then redirected to the `return_url`.
                        this.toggleIsTransactionComplete();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },

        /**
         * Find a game within the available games.
         */
        findAvailableGame(gameId) {
            return this.availableGames.find((game) => game.game_id === gameId);
        },
    },
};
</script>
